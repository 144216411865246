<template>

    <div class="w-full h-full flex flex-col justify-center items-center relative overflow-x-hidden" :class="'bg-bg-'+mode">

        <div v-if="mobile" class="absolute w-full h-full z-50 portrait-only flex items-center justify-center" :class="'bg-bg-'+mode">
            <div class="text-5xl text-center">
                
                <div class="text-8xl mb-4">
                    <i class="mdi mdi-phone-rotate-portrait" :class="'text-dfont-'+mode"></i>
                </div>

                <span :class="'text-dfont-'+mode">Gira tu dispositivo para visualizar correctamente la aplicación</span>
                
            </div>
        </div>

        <div class="relative h-full w-full">
            
            <div class="h-full w-full overflow-hidden z-30 flex flex-col">

                <div v-if="path != 'translations'" class="h-auto min-h-28 w-full rounded-b-xl p-2 px-4 z-40 overflow-x-hidden flex-none" :class="'bg-module-'+mode" style="box-shadow: 0px 2px 8px #9DA7B91F;">
                
                    <div class="h-auto flex flex-row justify-start items-center">
        
                        <div v-if="tests" class="h-full w-auto px-1 flex flex-row justify-start items-center">
        
                            <div v-if="path === 'company' && rolname !== 'Gerente' && rolname!== 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToGroup()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representante' && rolname !== 'Representante'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentants()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'zones' && rolname !== 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToCompany()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'zone' && rolname !== 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotozones()">
                                <i class="mdi mdi-arrow-left text-red text-lg"></i>
                            </div>
        
                            <div v-if="path === 'grouptops'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToGroup()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'clients'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotocomercial()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'comments'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="previouspage()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'groupfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToGroup()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'kpis'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotocomercial()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'kpiscomercialfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotokpis()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representants'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotocomercial()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'comercialfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotocomercial()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'companyfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToCompany()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'comercial' && rolname == 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer truncate" @click="goToComercials()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                                <!-- <span class="text-xs text-red truncate">{{ $t('commercials') }}</span> -->
                            </div>
        
                            <div v-if="path === 'zone' && rolname === 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer truncate" @click="goToComercials()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                                <!-- <span class="text-xs text-red truncate">{{ $t('commercials') }}</span> -->
                            </div>
        
                            <div v-if="path === 'comercials' && rolname === 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer truncate" @click="goToCompany()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                                <!-- <span class="text-xs text-red truncate">{{ $t('company') }}</span> -->
                            </div>
        
                            <div v-if="path === 'zones' && rolname === 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer truncate" @click="goToCompany()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                                <!-- <span class="text-xs text-red truncate">{{ $t('company') }}</span> -->
                            </div>
        
                            <div v-if="path === 'company' && rolname === 'Comercial'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer truncate" @click="goToGroup()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                                <!-- <span class="text-xs text-red truncate">{{ $t('group') }}</span> -->
                            </div>
        
                            <div v-if="path === 'company' && rolname === 'Gerente'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer trunate" @click="goToGroup()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                                <!-- <span class="text-xs text-red truncate">{{ $t('group') }}</span> -->
                            </div>
        
                            <div v-if="path === 'visits'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotocomercial()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'Representantclients'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentant()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'kpisrepresentante'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentant()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'filterperiod'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToGroup()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'filterperiodvisits'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotovisits()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'client'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotoclients()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representantfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentant()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'otherfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotozones()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'otherfiltersrepresentant'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentants()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representantvisits'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentant()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representantclient'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentantclients()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'filterstop'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="previouspage()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'companytops'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goToCompany()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'comercialtops'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotocomercial()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representanttops'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentant()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'comercialclienttops'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotoclient()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'representantclienttops'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotorepresentantclient()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'newuser'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gousers()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'users'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gohome()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'edituser'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gousers()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'indicatorfilterszones'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotozones()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'relations'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goedituser()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'clientfilters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotoclient()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'analitycsUser'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="analitycs()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'filtersAnalitycs'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="analitycsUser()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if="path === 'filters'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="gotoclients()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                            <div v-if=" path === 'profile' || path === 'analitycs' || path == 'syncs' || path == 'workdays'" class="h-full w-full flex flex-row justify-center items-center cursor-pointer"
                                @click="goback()">
                                <i class="mdi mdi-arrow-left text-red text-lg" ></i>
                            </div>
        
                        </div>
        
                        <div class="h-full flex-1 flex flex-row justify-start items-center truncate">
                            <span v-if="path == 'group' || (path == 'zone' && rolname == 'Comercial')" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode"><span :class="'text-font_gray-'+mode">{{ $t('hi') }}</span> <b>{{ user.name }} {{ user.surname1 }}</b></span>
                            <span v-else-if="path == 'company' || path == 'companytops' || path == 'zones'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ companyName }}</span>
                            <span v-else-if="path == 'zone' || path == 'kpis' || path == 'visits'|| path == 'clients' || path == 'comercialtops' || path == 'representants' || path == 'client'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('zone') }} {{ comercialSelected }}</span>
                            <span v-else-if="path == 'profile'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('volver') }}</span>
                            <span v-else-if="path == 'representante' || path == 'kpisrepresentante' || path == 'representantvisits' || path == 'Representantclients' || path == 'representantclient' || path == 'representanttops'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ employeSelected.Name }}</span>
                            <span v-else-if="path == 'comercialclienttops' || path == 'comments' || path == 'representantclienttops'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ client.Name }}</span>
                            <span v-else-if="path == 'analitycsUser'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('useApp') }}</span>
                            <span v-else-if="path != 'profile'" class="mr-auto truncate text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('volver') }}</span>
                        </div>
        
                        <img v-if="path == 'group' && mode == 'whitemode'" src="/img/logo_pamesa.png" class="h-6 mr-4">
                        <img v-else-if="mode == 'whitemode' && companySelected" :src="'/img/dark_logos/' + companySelected + '.png'" class="h-8 mr-2">
                        <img v-else-if="mode == 'whitemode'" src="/img/logo_pamesa.png" class="h-6 mr-4">
        
                        <img v-if="path == 'group' && mode == 'darkmode'" src="/img/LOGOGRUPOBLANCO.png" class="h-6 mr-4">
                        <img v-else-if="mode == 'darkmode' && companySelected" :src="'/img/white_logos/' + companySelected + '.png'" class="h-8 mr-2">
                        <img v-else-if="mode == 'darkmode'" src="/img/LOGOGRUPOBLANCO.png" class="h-6 mr-4">

        
                        <el-dropdown trigger="click">
        
                            <span class="el-dropdown-link">
                                <div  class="h-10 w-10 rounded-full flex justify-center items-center" :class="'bg-dfont-'+mode">
                                    <span class="font-semibold text-2xl" :class="'text-module-'+mode">{{ user.name.substr(0,1) }}</span>
                                </div>
                            </span>
        
                            <el-dropdown-menu slot="dropdown" class="flex flex-col justify-between items-start shadow-card h-auto z-50">
        
        
                                <el-dropdown-item class="w-full">
                                    <button class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                        @click="gohome()">
                                        <i class="text-xl mdi mdi-home mr-2" :class="'text-dfont-'+mode"></i>
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('home') }}</span>
                                    </button>
                                </el-dropdown-item>
                                
                                <el-dropdown-item class="w-full">
                                    <button class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                        @click="gotoprofile()">
                                        <i class="text-xl mdi mdi-account mr-2" :class="'text-dfont-'+mode"></i>
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('profile') }}</span>
                                    </button>
                                </el-dropdown-item>
        
                                <el-dropdown-item class="w-full" v-if="permisions">
                                    <button class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                        @click="users()">
                                        <i class="text-xl mdi mdi-account-multiple mr-2" :class="'text-dfont-'+mode"></i>
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('users') }}</span>
                                    </button>
                                </el-dropdown-item>
        
                                <el-dropdown-item class="w-full" v-if="permisions">
                                    <button class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                        @click="syncs()">
                                        <i class="text-xl mdi mdi-autorenew mr-2" :class="'text-dfont-'+mode"></i>
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('syncs') }}</span>
                                    </button>
                                </el-dropdown-item>
        
                                <el-dropdown-item class="w-full" v-if="permisions">
                                    <button class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                        @click="workdays()">
                                        <i class="text-xl mdi mdi-calendar mr-2" :class="'text-dfont-'+mode"></i>
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('labdays') }}</span>
                                    </button>
                                </el-dropdown-item>
        
                                <el-dropdown-item class="w-full" v-if="permisions || permisionsgerente || permisionspropiedad">
                                    <button class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                        @click="analitycs()">
                                        <i class="text-xl mdi mdi-poll mr-2" :class="'text-dfont-'+mode"></i>
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{ $t('useApp') }}</span>
                                    </button>
                                </el-dropdown-item>
        
                                <el-dropdown-item class="w-full">

                                    <Request
                                        model="Auth"
                                        action="logout"
                                        @success="logout"
                                        v-slot="{ request, loading }"
                                        ref="logout">

                                        <button
                                            class="w-full h-auto py-2 flex flex-row justify-start items-center px-2"
                                            @click="request"
                                            :disabled="loading">
                                            <i class="text-xl mdi mdi-power mr-2 text-red"></i>
                                            <span class="text-red">{{ $t('logOut') }}</span>
                                        </button>

                                    </Request>

                                </el-dropdown-item>
        
                            </el-dropdown-menu>
        
                        </el-dropdown>
        
                    </div>
        
                    <div class="h-10 w-full overflow-x-hidden truncate flex flex-row justify-start items-center">
        
                        <img v-if="path == 'kpis' || path == 'kpisrepresentante'" :src='"/img/kpis_" + mode + ".svg"' class="h-5 mr-1">
                        <img v-if="path == 'visits' || path == 'representantvisits' || path == 'client' " :src='"/img/visits_" + mode + ".svg"' class="h-5 mr-1">
                        <img v-if="path == 'clients'|| path == 'Representantclients'" :src='"/img/clients_" + mode + ".svg"' class="h-5 mr-1">
                        <img v-if="path == 'representants'" :src='"/img/representant_" + mode + ".svg"' class="h-5 mr-1">
        
                        <span v-if="path == 'group'" class="text-2xl font-medium" :class="'text-dfont-'+mode">{{ $t(path) }}</span>
                        <span v-if="path == 'company' || path == 'zone' || path == 'representante'" class="text-2xl font-medium" :class="'text-dfont-'+mode">{{ $t('general') }}</span>
                        <span v-if="path == 'zones'" class="text-2xl font-medium" :class="'text-dfont-'+mode">{{ $t('sales') }}</span>
                        <span v-if="path == 'kpis' || path == 'kpisrepresentante'" class="text-2xl font-medium" :class="'text-dfont-'+mode">KPIS</span>
                        <span v-if="path == 'visits'" class="text-2xl font-medium" :class="'text-dfont-'+mode">{{ $t('visitsZone') }}</span>
                        <span v-if="path == 'client'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ client.Name }}</span>
                        <span v-if="path == 'filterperiod' || path == 'kpiscomercialfilters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'profile'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('profile') }}</span>
                        <span v-if="path == 'users'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('users') }}</span>
                        <span v-if="path == 'analitycs'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('useApp') }}</span>
                        <span v-if="path == 'grouptops'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('topGroups') }}</span>
                        <span v-if="path == 'companytops'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">Tops</span>
                        <span v-if="path == 'comercialtops'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">Tops</span>
                        <span v-if="path == 'companyfilters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'filterstop'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'otherfilters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'clients'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('customers') }}</span>
                        <span v-if="path == 'representants'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('representatives') }}</span>
                        <span v-if="path == 'filterperiodvisits'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'filters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'clientfilters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'otherfiltersrepresentant'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'representantvisits'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('visits') }}</span>
                        <span v-if="path == 'Representantclients'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('customers') }}</span>
                        <span v-if="path == 'representantfilters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'comercialclienttops'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">Tops</span>
                        <span v-if="path == 'comments'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('comments') }}</span>
                        <span v-if="path == 'comercialfilters'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                        <span v-if="path == 'representantclient'" class="text-xl font-medium truncate" :class="'text-dfont-'+mode">{{ client.Name }}</span>
                        <span v-if="path == 'representantclienttops'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">Tops</span>
                        <span v-if="path == 'representanttops'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">Tops</span>
                        <span v-if="path == 'syncs'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('sincs') }}</span>
                        <span v-if="path == 'workdays'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('labdays') }}</span>
                        <span v-if="path == 'analitycsUser'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ userAnalitycs.name }}</span>
                        <span v-if="path == 'edituser'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('edituser') }}</span>
                        <span v-if="path == 'newuser'" class="text-2xl font-medium truncate" :class="'text-dfont-'+mode">{{ $t('newuser') }}</span>
                    
                    </div>
        
                    <div class="h-auto w-full flex flex-row justify-start items-center">
        
                        <div class="h-full w-full flex flex-wrap">
        
                            <div class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('period') }}</span>
                                <span class="text-red text-sm capitalize font-medium ">{{ periodo }}</span>
                            </div>
        
                            <div v-if="period == 'month'" class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('month') }}</span>
                                <span class="text-red text-sm capitalize font-medium">{{ monthName }}</span>
                            </div>
        
                            <div v-if="path == 'zones' || path == 'zone'" class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('commercial') }}</span>
                                <span class="text-red text-sm capitalize font-medium">{{ zone }}</span>
                            </div>
        
                            <div v-if="path == 'zones' || path == 'group' || path == 'filterperiod' || path == 'representants' || path == 'otherfiltersrepresentant' || path == 'comercialclienttops' || path == 'representantclienttops' || path == 'representanttops'" class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('indicator') }}</span>
                                <span class="text-red text-sm capitalize font-medium">{{ translateIndicator(dimensionName) }}</span>
                            </div>
        
                            <div v-if="path == 'grouptops' || path == 'companytops' || path == 'comercialtops'  || path == 'comercialclienttops' || path == 'representantclienttops' || path == 'representanttops'" class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('dimension') }}</span>
                                <span class="text-red text-sm capitalize font-medium">{{ translate(topdimensionname) }} {{clienttype}}</span>
                            </div>
        
                            <div v-if="path == 'grouptops' || path == 'companytops' || path == 'comercialtops'  || path == 'comercialclienttops' || path == 'representantclienttops' || path == 'representanttops'" class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('indicator') }}</span>
                                <span class="text-red text-sm capitalize font-medium">{{ topindicatorname }}</span>
                            </div>
        
                            <div v-if="path == 'grouptops' || path == 'companytops' || path == 'comercialtops'  || path == 'comercialclienttops' || path == 'representantclienttops' || path == 'representanttops'" class="h-auto w-auto flex flex-row justify-start items-center mr-3">
                                <span class="text-gray text-sm capitalize font-medium mr-1">{{ $t('place') }}</span>
                                <span class="text-red text-sm capitalize font-medium">{{ translate(topplacename || 'Todos') }}</span>
                            </div>
                            
        
                        </div>
        
                        <div v-if="path == 'group'"  class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="filterperiod">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
                        
                        <div v-if="path == 'company'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="/companyfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'zones'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="otherfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'zone'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="comercialfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'kpis'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="kpiscomercialfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'grouptops'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="filterstop">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'companytops'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center" @click="companytopsfilters()">
                            <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                        </div>
        
                        <div v-if="path == 'comercialtops'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center" @click="comercialtopsfilters()">
                            <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                        </div>
        
                        <div v-if="path == 'visits'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="filterperiodvisits">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'clients'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center" @click="clientsFilters()">
                            <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                        </div>
        
                        <div v-if="path == 'client'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="clientfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'representants'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="otherfiltersrepresentant">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'representante'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="/representantfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'kpisrepresentante'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="kpiscomercialfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'representantclient'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="representantfilters">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                        <div v-if="path == 'comercialclienttops'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center" @click="tocomercialclienttopfilter()">
                            
                            <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                    
                        </div>
        
                        <div v-if="path == 'representantclienttops'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center" @click="clienttopfilters()">
                            
                            <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                    
                        </div>
        
                        <div v-if="path == 'representanttops'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center" @click="clienttopfilters()">
                            
                            <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                    
                        </div>

                        <div v-if="path == 'analitycsUser'" class="h-6 w-4 ml-auto flex flex-col justify-center items-center">
                            <router-link to="filtersAnalitycs">
                                <i class="mdi mdi-dots-vertical text-red text-xl"></i>
                            </router-link>
                        </div>
        
                    </div>
                
                </div>

                <div class="flex flex-1 min-h-0 overflow-auto">
                    <slot></slot>
                </div>
                
            </div>
        
        </div>

    </div>

</template>

<script>
    import { StatusBar } from '@/native';
    import { Request } from '@/api/components';
    import { state, actions } from '@/store';
    import filters from '../../pages/filters/index.vue';

    export default {
        name: 'AppLayout',
        components: {
            StatusBar,
            Request,
            filters
        },
        data(){
            return {
                open: false,
                error: null,
                timeoutID: null
            }
        },
        methods:{
            tocomercialclienttopfilter(){
                actions.setLastPage(this.$route.name)
                this.$router.push('filterstop')
            },
            translate(word) {
                switch (word) {
                    case 'Paises':
                        return this.$t("countries")
                        break;
                    case 'SuperFamilias':
                        return this.$t("superFamily")
                        break;
                    case 'Facturación':
                        return this.$t("billing")
                        break;
                    case 'Provincias de España':
                        return this.$t("spanishProvinces")
                        break;
                    case 'Todos los continentes':
                        return this.$t("allContinents")
                        break;
                    case 'Continentes':
                        return this.$t("continents")
                        break;
                    case 'Formatos':
                        return this.$t("formats")
                        break;
                    case 'Clientes':
                        return this.$t("customers")
                        break;
                    case 'Calidad':
                        return this.$t("quality")
                        break;
                    case 'Marcas':
                        return this.$t("brands")
                        break;
                    case 'Modelos':
                        return this.$t("models")
                        break;
                    case 'Colores':
                        return this.$t("colors")
                        break;
                    case 'Todos':
                        return this.$t("all")
                        break;
                    default:{
                        return word
                    }
                }
            },
            previouspage(){ 
                this.$router.go(-1) 
            },
            gotoprofile(){ 
                actions.setLastPage(this.$route.name)
                this.$router.push({ name: 'profile' }) 
            },
            goToComercials() {
                this.$router.push({name: 'zones', params: {saletype:this.saleTypeSelected}})
            },
            goToCompany(){ 
                this.$router.push({name: 'company', params:{company: this.companySelected}}) 
            },
            goToGroup(){ 
                this.$router.push({name: 'group'}) 
            },
            gohome() {
                switch (this.rolname) {
                    case 'Propiedad':
                        this.$router.push({name: 'group'})
                        break;
                    case 'Gerente':
                        this.$router.push({name: 'company'})
                        break;
                    case 'Comercial':
                        if (this.path !== 'zone') {
                            this.reloadComercial();
                            this.$router.push({name: 'zone'}) }
                        else if (!state.comercialSelected) { actions.setComercialSelected(state.user.id) }
                        break;
                    case 'Representante':
                        this.$router.push({name:'representante'})
                        break;
                }
            },
            color(company) {
                
                if(this.mode == 'whitemode'){

                    switch (company) {
                        case 45:
                            return 'bg-pamesa-whitemode'
                            break;
                        case 28:
                            return 'bg-geotiles'
                            break;
                        case 30:
                            return 'bg-prissmacer'
                            break;
                        case 31:
                            return 'bg-ecoceramic'
                            break;
                        case 32:
                            return 'bg-navarti'
                            break;
                        case 36:
                            return 'bg-tauceramica'
                            break;
                        case 'Propiedad':
                            return 'bg-pamesa-whitemode'
                            break;
                        case 'Representante':
                            return 'bg-pamesa-whitemode'
                            break;
                    }

                } else {


                    switch (company) {
                        case 45:
                            return 'bg-pamesa-darkmode'
                            break;
                        case 28:
                            return 'bg-geotiles'
                            break;
                        case 30:
                            return 'bg-prissmacer'
                            break;
                        case 31:
                            return 'bg-ecoceramic'
                            break;
                        case 32:
                            return 'bg-navarti'
                            break;
                        case 36:
                            return 'bg-tauceramica'
                            break;
                        case 'Propiedad':
                            return 'bg-pamesa-darkmode'
                            break;
                        case 'Representante':
                            return 'bg-pamesa-darkmode'
                            break;
                    }

                }

            },
            reloadComercial() {
                actions.setComercialSelected(state.user.id)
                actions.setComercialLogged(state.user)
                if (state.user.relation[0].company.FrontName) {
                    actions.setCompanySelected(state.user.relation[0].company.FrontName) }
                else { this.error = true }

                if (state.user.relation[0].IdCompany) {
                    actions.setIdCompany(state.user.relation[0].IdCompany.toUpperCase()) }
                else { this.error = true }

                if (state.user.relation[0].IdSaleType) {
                    actions.setSaleTypeSelected(state.user.relation[0].IdSaleType) }
                else { this.error = true }

                if (state.user.relation[0].IdZoneLink) {
                    actions.setIdZone(state.user.relation[0].zonelink.IdZone) }
                else { this.error = true }

                if (state.user.relation[0].saletype) {
                    actions.setZoneSelected(state.user.relation[0].saletype.Name) }
                else { this.error = true }

                if (state.user.relation[0].zonelink) {
                    // actions.setComercialSelected(state.user.relation[0].zonelink.zone);
                    actions.setZone(state.user.relation[0].zonelink.zone) }
                else { this.error = true }

                if (state.user.relation[0].saletype) {
                    actions.setTypeComercialsSelected(state.user.relation[0].saletype.Name) }
                else { this.error = true }

                if (state.user.relation[0].zonelink) {
                    actions.setFilterZoneComercial(state.user.relation[0].zonelink.IdZone);
                    actions.setFilterZoneComercialName(state.user.relation[0].zonelink.zone.Name) }
                else { this.error = true }

                if (state.user.relation[0].company.IndustrialGroup === 0) {
                    actions.setIndustrialGroup(null) }
                else { actions.setIndustrialGroup(true) }

                if (state.user.id) { actions.setUserId(state.user.id) }
                else { this.error = true }

                if (state.user.relation) { actions.setRelation(state.user.relation) }
                else { this.error = true }

                if (!this.error) { return { name : 'zone' } }
                else {
                    element.Message.error('El usuario no tiene los datos necesarios');
                    actions.resetAuth();
                }

                if (state.zoneSelected === 'Nacional') {
                    actions.setTopDimension('Province');
                    actions.setTopDimensionName('Provincias de España') }
                else {
                    actions.setTopDimension('Country');
                    actions.setTopDimensionName('Paises');
                }
            },
            gotocomercial(){
                this.$router.push({ name:'zone' }) 
            },
            createUser(){
                this.$router.push({ name: 'newuser' }) 
            },
            users(){
                this.$router.push({ name: 'users' }) 
            },
            gotorepresentant(){
                this.$router.push({ name: 'representante' }) 
            },
            gotorepresentants(){
                this.$router.push({ name: 'representants' }) 
            },
            gotozones(){
                this.$router.push({ name: 'zones' }) 
            },
            gotokpis(){
                this.$router.push({ name:'kpis' }) 
            },
            gotovisits(){
                this.$router.push({ name:'visits' }) 
            },
            gotorepresentantclients(){
                this.$router.push({ name:'Representantclients' }) 
            },
            gotoclient(){
                this.$router.push( {name:'client'} ) 
            },
            gotorepresentantclient(){
                this.$router.push({name:'representantclient'}) 
            },
            gousers(){
                this.$router.push({name:'users'}) 
            },
            gotoclients(){ 
                if(state.lastPage){
                    this.$router.push({name: state.lastPage})
                } else {
                    this.$router.push({ name:'clients' }) 
                }
            },
            goedituser(){ 
                this.$router.push({ name:'edituser' }) 
            },
            syncs(){
                this.$router.push({name:'syncs'})
            },
            workdays(){
                actions.setLastPage(this.$route.name)
                this.$router.push('workdays')
            },
            analitycs(){
                this.$router.push({ name:'analitycs' })
            },
            analitycsUser(){
                this.$router.push( { name:'analitycsUser' } ) 
            },
            translateIndicator(word) {
                switch (word) {
                    case 'Precio medio':
                        return this.$t("averagePrice")
                        break;
                    case 'Metros cuadrados':
                        return this.$t("squareMeters")
                        break;
                    case 'Facturación':
                        return this.$t("billing")
                        break;
                    case 'Margen':
                        return this.$t("margin")
                        break;
                }
            },
            companytopsfilters(){
                actions.setLastPage(this.$route.name)
                this.$router.push('filterstop')
            },
            comercialtopsfilters(){
                actions.setLastPage(this.$route.name)
                this.$router.push('filterstop')
            },
            clientsFilters(){
                actions.setLastPage(this.$route.name)
                this.$router.push('filters')
            },
            clienttopfilters(){
                actions.setLastPage(this.$route.name)
                this.$router.push('filterstop')
            },
            goback(){
                this.$router.push({name: state.lastPage})
            },
            logout(){
                // this.axios.post('/logout').then(function (response){

                    actions.resetAuth();
                    window.location.reload();

                // });
            },
            setup(){
                addEventListener("mousemove", this.resetTimer, false);
                addEventListener("mousedown", this.resetTimer, false);
                addEventListener("keypress", this.resetTimer, false);
                addEventListener("DOMMouseScroll", this.resetTimer, false);
                addEventListener("mousewheel", this.resetTimer, false);
                addEventListener("touchmove", this.resetTimer, false);
                addEventListener("MSPointerMove", this.resetTimer, false);

                this.startTimer();
            },
            startTimer() {
                this.timeoutID = window.setTimeout(this.goInactive, 600000);
            },
            resetTimer(e) {
                window.clearTimeout(this.timeoutID);

                this.goActive();
            },
            goInactive() {
                
                if(state.token){

                    this.axios.post('/logout').then(function (response){

                        actions.resetAuth();
                        window.location.reload();

                    });

                }
                
            },
            goActive() {
                this.startTimer();
            }
        },
        computed:{
            userAnalitycs(){
                return state.userAnalitycs
            },
            topindicatorname() {
                if (state.topIndicatorName !== 'Metros cuadrados') {
                    if (state.topIndicatorName === 'Facturación') { return this.$t("billing") }
                    if (state.topIndicatorName === 'Precio medio') { return this.$t("averagePrice") }
                    if (state.topIndicatorName === 'Margen') { return this.$t("margin") }
                    else { return state.topIndicatorName }
                }
                else { return this.$t("meters")}
            },
            topdimensionname(){
                return state.topDimensionName 
            },
            path(){
                return this.$route.name 
            },
            dimensionName(){
                return state.dimensionName 
            },
            user(){
                return state.user 
            },
            rolname(){
                return state.user.rol.Name 
            },
            saleTypeSelected(){
                return state.saleTypeSelected 
            },
            companySelected(){
                return state.idCompany 
            },
            company() {
                if(this.rolname !== 'Propiedad') {
                    if (state.user.rol.Name === 'Representante') { return 'Representante'}
                    else { return state.user.relation[0].company.IdLink }
                }
                else { return 'Propiedad' }
            },
            tests() {
                if (this.path !== 'group' && this.rolname === 'Propiedad')
                    { return true }
                if (this.rolname !== 'Representante' && this.path !== 'group' )
                    { return true }
                if (this.rolname === 'Representante' && this.path !== 'representante')
                    { return true }
            },
            permisions() {
                if (state.user.admin === 1) { return true }
                else { return false }
            },
            permisionsgerente(){
                if(state.user.rol.Name == 'Gerente'){
                    return true
                }
            },
            permisionspropiedad(){
                if(state.user.rol.Name == 'Propiedad'){
                    return true
                }
            },
            mode(){
                return state.mode
            },
            statuscolor(){

                if(this.mode == 'whitemode'){
                    return '#d91016'
                } else {
                    return '#b73030'
                }

            },
            periodo(){
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            companyName(){
                return state.companySelected
            },
            zone(){ 
                return state.zoneSelected 
            },
            comercialSelected(){
                return state.zone.Name
            },
            client(){
                return state.clientSelected
            },
            topplacename(){ 
                return state.topPlaceName 
            },
            period(){
                return state.period
            },
            employeSelected(){
                return state.employeSelected
            },
            clienttype(){
                return state.typeclienttops
            },
            mobile(){
                return state.isMobile
            }
        },
        created() {
            StatusBar.setBackgroundColor(this.statuscolor);
            StatusBar.setStyle('Dark');
            this.setup();
        }
    }
</script>

<style>

    .el-dropdown-menu{
        background-color: transparent !important;
        border: transparent !important;
        backdrop-filter: blur(100px);
    }

    .popper__arrow{
        
        border-bottom-color: transparent !important;
    }

    .blur{
        background-color: transparent !important;
        backdrop-filter: blur(50px);
    }

</style>
